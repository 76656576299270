import Box, { BoxProps } from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { PortfolioItem } from "../../../../types";
import { ImageCard } from "./Card";

interface ImagesGridProps extends BoxProps {
  headerComponent?: React.ElementType;
  category: string;
  items: PortfolioItem[];
  onCardClick?: (cardId: string) => void;
}

export const ImagesGrid = (props: ImagesGridProps) => {
  const { headerComponent = "h3", category, items, onCardClick, ...otherProps } = props;

  return (
    <Box {...otherProps}>
      <Typography
        component={headerComponent}
        variant="h3"
        textAlign={{ xs: "center", sm: "left" }}
        sx={{ marginBottom: { xs: 2, md: 4 } }}
      >
        {category}
      </Typography>
      <Grid container={true} spacing={1}>
        {items.map((item) => (
          <Grid key={item.id} item={true} xs={12} sm={6} md={3}>
            <ImageCard item={item} onClick={onCardClick} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
