import Box from "@mui/material/Box";
import React, { useCallback } from "react";
import { PortfolioItem } from "../../../../types";
import { Image } from "../../../core/Image";

interface ImagesListCardProps {
  item: PortfolioItem;
  onClick?: (id: string) => void;
}

export const ImageCard = (props: ImagesListCardProps) => {
  const { item, onClick } = props;

  const handleCardClick = useCallback(() => {
    onClick?.(item.id);
  }, [item, onClick]);

  return (
    <Box
      sx={{
        cursor: "pointer",
        zIndex: 1,
        width: "100%",
      }}
      onClick={handleCardClick}
    >
      <Box margin="0 auto" maxWidth="700px">
        <Image image={item?.image?.gatsbyImageData} alt={item.title} />
      </Box>
    </Box>
  );
};
