import Box from "@mui/material/Box";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const MotionBox = motion(Box);

export interface OverlayProps {
  isVisible: boolean;
  onClick?: () => void;
}

export const Overlay = (props: OverlayProps) => {
  const { isVisible, onClick } = props;

  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <MotionBox
          initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
          animate={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
          exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
          sx={{ zIndex: 1200, position: "fixed", inset: 0 }}
          onClick={onClick}
        />
      )}
    </AnimatePresence>
  );
};
