import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../components/layout/layout";
import { PortfolioSection } from "../components/sections/Portfolio";
import { SEO } from "../components/SEO";
import { socialLinks } from "../data/links";
import { PortfolioItem } from "../types/project";

export const pageQuery = graphql`
  query PortfolioPage {
    site {
      siteMetadata {
        pages {
          portfolio {
            title
            description
            keywords
          }
        }
      }
    }
    portfolioProjects: allMdx(
      filter: { fields: { source: { eq: "portfolio" } }, frontmatter: { hidden: { eq: false } } }
      sort: { fields: frontmatter___category }
    ) {
      nodes {
        id
        frontmatter {
          title
          category
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

interface PortfolioPageProps extends PageProps {
  data: Queries.PortfolioPageQuery;
}

const PortfolioPage = (props: PortfolioPageProps) => {
  const { data } = props;
  const { site, portfolioProjects } = data;

  const projects = portfolioProjects.nodes.map<PortfolioItem>(({ id, frontmatter }) => ({
    id: id,
    title: frontmatter?.title ?? "",
    category: frontmatter?.category ?? "",
    image: frontmatter?.featuredImage?.childImageSharp as Queries.ImageSharp,
  }));

  const seo = site?.siteMetadata?.pages?.portfolio;

  return (
    <Layout>
      <SEO title={seo?.title} description={seo?.description} keywords={[...(seo?.keywords ?? [])]} />
      <PortfolioSection items={projects} featuredLink={socialLinks.artStation} />
    </Layout>
  );
};

export default PortfolioPage;
