import Box from "@mui/material/Box";
import { AnimatePresence } from "framer-motion";
import { findIndex, groupBy } from "lodash";
import React, { useMemo, useState } from "react";
import { useLockBodyScroll } from "react-use";
import { AppLink, PortfolioItem } from "../../../types";
import { Overlay } from "../../core/Overlay";
import { ImagesGrid } from "./ImagesGrid";
import { Preview } from "./Preview";

interface PortfolioSectionProps {
  items: PortfolioItem[];
  featuredLink?: AppLink;
}

export const PortfolioSection = (props: PortfolioSectionProps) => {
  const { items, featuredLink } = props;

  const [selectedItemId, setSelectedItemId] = useState<string | null>();
  const selectedItemIdx = useMemo(() => {
    return findIndex(items, (item) => item.id === selectedItemId);
  }, [items, selectedItemId]);

  const groups = groupBy(items, "category");
  const categories = Object.keys(groups);

  useLockBodyScroll(Boolean(selectedItemId));

  const handleCardClick = (cardId: string) => {
    setSelectedItemId(cardId);
  };

  const handlePrevClick = () => {
    const prevIdx = selectedItemIdx - 1;
    if (prevIdx < 0) return;

    setSelectedItemId(items[prevIdx].id);
  };

  const handleNextClick = () => {
    const nextIdx = selectedItemIdx + 1;
    if (nextIdx >= items.length) return;

    setSelectedItemId(items[nextIdx].id);
  };

  const handleClose = () => {
    setSelectedItemId(null);
  };

  return (
    <Box component="section" marginY={8} paddingX={2}>
      <Overlay isVisible={Boolean(selectedItemId)} onClick={handleClose} />
      <AnimatePresence initial={false}>
        {selectedItemIdx >= 0 && (
          <Preview
            image={items[selectedItemIdx].image}
            prevEnabled={selectedItemIdx > 0}
            nextEnabled={selectedItemIdx < items.length - 1}
            featuredLink={featuredLink}
            onPrevClick={handlePrevClick}
            onNextClick={handleNextClick}
            onClose={handleClose}
          />
        )}
      </AnimatePresence>
      {categories.map((category, idx) => (
        <ImagesGrid
          key={category}
          headerComponent={idx === 0 ? "h1" : "h3"}
          category={category}
          marginTop={{ xs: 4, md: 8 }}
          items={groups[category]}
          onCardClick={handleCardClick}
        />
      ))}
    </Box>
  );
};
