import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { AppLink } from "../../../../types";
import { Button } from "../../../core/Button";
import { Image } from "../../../core/Image";
import { SmartLink } from "../../../core/SmartLink";

const MotionBox = motion(Box);

const PrimaryIconButton = (props: IconButtonProps) => {
  const { sx, ...otherProps } = props;

  return <IconButton color="primary" sx={{ position: "absolute", zIndex: 2, ...sx }} {...otherProps} />;
};

export interface PreviewProps {
  image?: Queries.ImageSharp;
  nextEnabled?: boolean;
  prevEnabled?: boolean;
  featuredLink?: AppLink;
  onNextClick?: () => void;
  onPrevClick?: () => void;
  onClose?: () => void;
}

export const Preview = (props: PreviewProps) => {
  const { image, nextEnabled = false, prevEnabled = false, featuredLink, onNextClick, onPrevClick, onClose } = props;

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          onPrevClick?.();
          break;

        case "ArrowRight":
          onNextClick?.();
          break;

        default:
          break;
      }
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [onNextClick, onPrevClick]);

  return (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={1500}
      display="flex"
      sx={{ pointerEvents: "none" }}
    >
      <Box flexGrow={1} marginX={{ xs: 2, md: 10 }} marginY="auto" sx={{ pointerEvents: "auto" }} position="relative">
        <PrimaryIconButton sx={{ top: { xs: 0, md: 8 }, right: { xs: 0, md: 8 } }} onClick={onClose}>
          <CloseIcon fontSize="large" />
        </PrimaryIconButton>
        <PrimaryIconButton
          disabled={!prevEnabled}
          sx={{ top: "calc(50% - 20px)", left: { xs: 0, md: 8 } }}
          onClick={onPrevClick}
        >
          <ArrowBackIosNewIcon fontSize="large" />
        </PrimaryIconButton>
        <PrimaryIconButton
          disabled={!nextEnabled}
          sx={{ top: "calc(50% - 20px)", right: { xs: 0, md: 8 } }}
          onClick={onNextClick}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </PrimaryIconButton>
        {image && (
          <Box onClick={onClose}>
            <Image image={image?.gatsbyImageData} alt="" objectFit="fill" />
          </Box>
        )}
        {featuredLink && (
          <SmartLink
            path={featuredLink?.path}
            external={featuredLink?.external}
            sx={{ display: { xs: "none", md: "block" }, position: "absolute", top: "4%", left: "2%", zIndex: 1 }}
          >
            <Button>{featuredLink.label}</Button>
          </SmartLink>
        )}
      </Box>
    </MotionBox>
  );
};
